import { Controller } from "stimulus";
import flatpickr from "flatpickr";
import { it } from "flatpickr/dist/l10n/it.js";
import rangePlugin from "flatpickr/dist/plugins/rangePlugin.js";

export default class extends Controller {
  static values = { uid: String };

  connect() {
    const divId = this.uidValue;
    console.log("#" + divId + " .rangedatetimepicker");
    flatpickr("#" + divId + " .rangedatetimepicker", {
      altInput: true,
      altFormat: "d/m/Y H:i",
      enableTime: true,
      dateFormat: "Y-m-d H:i",
      locale: "it",
      time_24hr: true,
      plugins: [new rangePlugin({ input: "#" + divId + " .end-date" })],
    });
  }
}
