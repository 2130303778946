import { Controller } from "stimulus";
import flatpickr from "flatpickr";
import { it } from "flatpickr/dist/l10n/it.js";
import rangePlugin from "flatpickr/dist/plugins/rangePlugin.js";

export default class extends Controller {
  static targets = [];

  connect() {}
}
