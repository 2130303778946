import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "sameAdministrative",
    "sameLegal",
    "branchAddress",
    "branchCity",
    "branchZip",
    "branchDelivery",
    "branchPhone",
    "employeeName",
    "employeeRole",
    "employeePhone",
    "employeeEmail",
  ];

  customer = {};

  connect() {
    var element = document.getElementById("customer-fields");
    this.customer = JSON.parse(element.attributes.customer.value);
    this.mainCenterSameAdministrative();
    this.mainCenterSameLegal();
  }

  mainCenterSameAdministrative() {
    var centerSameAdministrative = document.querySelector(
      ".same-as-administrative:checked"
    );
    if (centerSameAdministrative) {
      if (centerSameAdministrative.value == "true") {
        document.getElementById("centers-div").classList.remove("h-0");
        document.getElementById("centers-div").classList.remove("opacity-0");
        document.getElementById("same-as-legal").classList.add("hidden");
        var element = document.querySelector(
          ".main-center-fields .main-center-details"
        );
        this.disableFields(element);
        this.branchAddressTarget.value = this.customer.administrative_address;
        this.branchCityTarget.value = this.customer.administrative_city;
        this.branchZipTarget.value = this.customer.administrative_zip_code;
        this.branchPhoneTarget.value = this.customer.administrative_phone;
        this.branchDeliveryTargets.forEach((element) => {
          if (
            element.value ==
            this.customer.administrative_product_delivery.toString()
          ) {
            element.checked = true;
          }
        });
      } else {
        document.getElementById("same-as-legal").classList.remove("hidden");
        var element = document.querySelector(
          ".main-center-fields .main-center-details"
        );
        this.enableFields(element);
        this.mainCenterSameLegal();
      }
    }
  }

  mainCenterSameLegal() {
    var centerSameLegal = document.querySelector(".same-as-legal:checked");
    if (centerSameLegal) {
      if (centerSameLegal.value == "true") {
        document.getElementById("centers-div").classList.remove("h-0");
        document.getElementById("centers-div").classList.remove("opacity-0");
        var element = document.querySelector(
          ".main-center-fields .main-center-details"
        );
        this.disableFields(element);
        this.branchAddressTarget.value = this.customer.legal_address;
        this.branchCityTarget.value = this.customer.legal_city;
        this.branchZipTarget.value = this.customer.legal_zip_code;
        this.branchPhoneTarget.value = this.customer.legal_phone;
        this.branchDeliveryTargets.forEach((element) => {
          if (
            element.value == this.customer.legal_product_delivery.toString()
          ) {
            element.checked = true;
          }
        });
      } else {
        document.getElementById("centers-div").classList.remove("h-0");
        document.getElementById("centers-div").classList.remove("opacity-0");
        var element = document.querySelector(
          ".main-center-fields .main-center-details"
        );
        this.enableFields(element);
      }
    }
  }

  managerSameOwner(event) {
    var baseNode = event.target.closest(".employees-fields");
    var managerSame = baseNode.querySelector(".manager-same-owner:checked");
    if (managerSame) {
      if (managerSame.value == "true") {
        var element = baseNode.querySelector(".manager-fields");
        this.disableFields(element);
        baseNode.querySelector(".branch-form-name").value = this.customer.owner;
        baseNode.querySelector(".branch-form-role").value =
          "Responsabile negozio";
        baseNode.querySelector(
          ".branch-form-phone"
        ).value = this.customer.mobile_phone;
        baseNode.querySelector(
          ".branch-form-email"
        ).value = this.customer.owner_email;
      } else {
        document.getElementById("same-as-legal").classList.remove("hidden");
        var element = baseNode.querySelector(".manager-fields");
        this.enableFields(element);
        this.mainCenterSameLegal();
      }
    }
  }

  disableFields(element) {
    element.querySelectorAll(`input`).forEach((element) => {
      element.classList.add("bg-gray-100");
      element.readOnly = true;
    });
    element.querySelectorAll(`label, .label`).forEach((element) => {
      element.classList.add("text-gray-400");
    });
  }

  enableFields(element) {
    element.querySelectorAll(`input`).forEach((element) => {
      element.classList.remove("bg-gray-100");
      element.readOnly = false;
    });
    element.querySelectorAll(`label, .label`).forEach((element) => {
      element.classList.remove("text-gray-400");
    });
  }

  legalChange() {
    var adminstrativeSameLegal = document.querySelector(
      ".administrative-same-legal:checked"
    );
    if (adminstrativeSameLegal && adminstrativeSameLegal.value == "true") {
      this.administrativeAddressTarget.value = this.legalAddressTarget.value;
      this.administrativeCityTarget.value = this.legalCityTarget.value;
      this.administrativeZipTarget.value = this.legalZipTarget.value;
      var legalProductDelivery = document.querySelector(
        ".legal-product-delivery:checked"
      );
      if (legalProductDelivery) {
        this.administrativeDeliveryTargets.forEach((element) => {
          if (element.value == legalProductDelivery.value) {
            element.checked = true;
          }
        });
      }
    }
  }
}
